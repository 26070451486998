
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  padding: 36px 10px 36px 50px;
  color: #333;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  .title-box {
    display: flex;
    align-items: center;
    .line {
      width: 4px;
      height: 20px;
      background: #1122d8;
      border-radius: 10px;
      margin-right: 8px;
    }
    .title {
      color: #333333;
      font-weight: 700;
      font-size: 16px;
    }
  }
  .subject-box {
    height: 80px;
    padding: 20px;
    background: #f0f2f5;
    margin-top: 20px;
    border-radius: 4px;
    margin-right: 40px;
  }
  .main-box {
    display: flex;
    .content-box {
      flex: 1;
      padding-top: 24px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: 40px;
    }
    .topic-answer {
      margin: 20px 0;
    }
  }
  .student-answer {
    height: calc(100% - 520px);
    background: #f0f2f5;
    padding: 20px;
    border-radius: 4px;
    // display: flex;
    // flex-direction: column;
    margin-right: 40px;
    .answer-title {
      height: 20px;
      font-weight: 700;
    }
    .answer-content {
      height: calc(100% - 72px);
      overflow-y: auto;
      .student-answer-item {
        min-height: 60px;
        padding: 16px 0;
        font-size: 12px;
        border-bottom: 1px solid #dcdfe6;
        display: flex;
        justify-content: space-between;
        .answer {
          flex: 1;
        }
      }
    }
    .answer-btn {
      height: 32px;
      margin-top: 20px;
    }
  }
  .mian-text {
    font-size: 12px;
    margin-top: 10px;
    // height: 48px;
  }
  .two-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
